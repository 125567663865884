/* You can add global styles to this file, and also import other style files */


// Global icon font
@import "./assets/fonts/feather/style.css";


// // 3rd party plugins
@import "@mdi/font/css/materialdesignicons.min.css";
@import "highlight.js/styles/googlecode.css";
@import "animate.css/animate.min.css";
@import "@ng-select/ng-select/themes/default.theme.css";
@import "metismenujs/scss/metismenujs";
@import "dropzone/dist/min/dropzone.min.css";
@import "quill/dist/quill.snow.css";


// NobleUI styles for LTR
@import "./assets/scss/style";


// NobleUI styles For RTL
// @import "./assets/scss/style.rtl.css";

@import 'sweetalert2/src/sweetalert2.scss';

@import 'ag-grid-community/styles/ag-grid.css';
@import 'ag-grid-community/styles/ag-theme-material.css';

html,
body {
    height: 100%;
}

body {
    margin: 0;
    font-family: Roboto, "Helvetica Neue", sans-serif;
}

.custom-table .mat-mdc-table {
    //width: 100%;
    border-spacing: 0;
    /* Sin espacio entre las celdas */
    border-collapse: collapse;
    background-color: #ffffff;
    /* Fondo blanco de la tabla */
    border-radius: 8px;
    /* Bordes redondeados */
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
    /* Sombra ligera para dar profundidad */
}

.custom-table .mat-mdc-header-row {
    background-color: #e3f2fd;
    /* Azul claro en el encabezado */
}

.custom-table .mat-mdc-header-cell {
    font-weight: bold;
    font-size: 14px;
    color: #1565c0;
    /* Azul oscuro para el texto */
    padding: 16px;
    text-align: left;
    border-bottom: 2px solid #bbdefb;
    /* Línea divisoria clara */
}

.custom-table .mat-mdc-row {
    background-color: #ffffff;
    transition: background-color 0.3s ease;
}

.custom-table .mat-mdc-row:hover {
    background-color: #f5f5f5;
    /* Cambio de color suave al hacer hover */
}

.custom-table .mat-mdc-cell {
    font-size: 14px;
    color: #424242;
    /* Gris oscuro para el texto */
    padding: 12px;
    border-bottom: 1px solid #eeeeee;
    /* Divisores ligeros entre filas */
}

.custom-table .mat-mdc-cell:first-of-type {
    border-left: none;
}

.custom-table .mat-mdc-cell:last-of-type {
    border-right: none;
}

/* Efecto en las celdas al hacer hover */
.custom-table .mat-mdc-cell:hover {
    color: #1976d2;
    /* Cambia el color del texto a un azul más oscuro en hover */
}

.custom-table .mat-mdc-pagination {
    display: flex;
    justify-content: flex-end;
    padding: 16px;
    background-color: #e3f2fd;
    /* Azul claro de fondo en la paginación */
    border-top: 1px solid #bbdefb;
    /* Divisor de paginación */
}

.custom-table .mat-mdc-paginator-icon {
    color: #1976d2;
    /* Color del ícono de la paginación */
}

.custom-table .mat-mdc-paginator-range-label {
    font-size: 12px;
    color: #1976d2;
    /* Texto en la paginación */
}

.mat-cell-button-icon {
    background: transparent;
    border: none;
}

.search-container {
    display: flex;
    justify-content: flex-end;
    padding-top: 10px;
    /* Separar del contenido de la tabla */
}

.drop-container {
    display: flex;
    justify-content: flex-start;
    padding: 20px 20px 0px 0px;
    /* Separar del contenido de la tabla */
}

.mat-mdc-form-field {
    width: 300px;
    /* Ajusta el tamaño del campo de búsqueda */
    // background-color: #f9f9f9;
    /* Fondo claro para el campo */
    border-radius: 1px;
    /* Borde ligeramente redondeado */
}

.mat-mdc-form-field-appearance-outline .mat-form-field-outline {
    color: #bbdefb !important;
    /* Color del borde del campo */
}

.mat-mdc-form-field-appearance-outline .mat-form-field-outline-thick {
    border-color: #1976d2;
    /* Borde resaltado cuando el campo está activo */
}

.mat-mdc-input-element {
    color: #424242;
    /* Color del texto en el campo de entrada */
    font-size: 14px;
    /* Tamaño de la fuente */
}

.mat-mdc-icon-button {
    color: #1976d2;
    /* Azul oscuro para el icono de búsqueda */
    background-color: transparent;
    transition: background-color 0.3s ease;
}

.mat-mdc-icon-button:hover {
    background-color: rgba(25, 118, 210, 0.1);
    /* Fondo azul claro al hacer hover */
    border-radius: 50%;
    /* Redondeado en hover */
}

.mat-mdc-form-field.mat-focused .mat-form-field-label {
    color: #1976d2;
    /* Color del label al estar enfocado */
}

/* Opcional: si quieres hacer que el icono de búsqueda destaque más en dispositivos móviles */
@media (max-width: 600px) {
    .search-container {
        width: 100%;
        /* Ocupa todo el ancho en pantallas pequeñas */
        justify-content: center;
    }

    .mat-mdc-form-field {
        width: 100%;
    }
}

.table-responsive {
    overflow-x: hidden;
}

.fc-scroller .fc-scroller-liquid-absolute {
    overflow: hidden scroll !important;
}

.fc .fc-timegrid-slot {
    height: 62px !important;
}