// Write your custom css/scss here
// a {
//     text-decoration: none;
//   }

//   i {
//     vertical-align: middle;
//     font-size: 24px;
//   }

.main-fab {
    position: absolute;
    z-index: 20;
    font-size: 30px;
    top: 100px;
    left: 24px;
    transform: scale(.88, .88);
}

.md-breadcrumb {
    padding-left: 88px;
}

.JCLRgrip:first-child {
    left: 105px !important;
    display: none !important;
}

.JColResizer th:first-child {
    width: 105px !important;
}

.md-table {
    min-width: 100%;
    border-collapse: collapse;
}

.md-table tbody tr:hover,
.md-table tbody tr:focus {
    cursor: pointer;
    background-color: rgba(63, 81, 181, 0.2);
}

.md-table-header {
    border-bottom: 1px solid rgb(230, 230, 230);
    color: rgb(130, 130, 130);
    text-align: left;
    font-size: 0.75em;
    font-weight: 700;
    padding: 16px 16px 16px 0;
}

.md-table-header a {
    text-decoration: none;
    color: inherit;
}

.md-table-content {
    white-space: nowrap;
    font-size: 0.8em;
    padding: 16px 16px 16px 0;
    height: 72px;
}

.md-table-td-more {
    max-width: 72px;
    width: 72px;
    padding: 16px;
}

.md-table-thumbs {
    max-width: 104px;
    width: 104px;
    padding: 16px 32px;
}

.md-table-thumbs div {
    overflow: hidden;
    width: 40px;
    height: 40px;
    border-radius: 20px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    background-size: cover;
    box-shadow: 0 8px 10px rgba(0, 0, 0, .3);
    -webkit-box-shadow: 0 8px 10px rgba(0, 0, 0, .1);
}

.md-table-footer {
    height: 40px;
    padding-left: 32px;
    padding-right: 32px;
}

.md-table-count-info {
    line-height: 40px;
    font-size: .75em;
}

.md-table-footer-item {
    width: 40px;
    height: 40px;
    vertical-align: middle;
}

.md-table-active-page {
    font-weight: 700;
}

//   .bold {
//     font-weight: 700;
//   }

//   .grey {
//     color: grey;
//   }

md-input-container.md-default-theme .md-input {
    color: white;
    border-color: white;
    margin-top: 24px;
}